import React from "react";
import "bulma/css/bulma.css";
import "../styles/global.css";

import SketchCollection from "../components/sketchesCollection";

import { StaticQuery, graphql } from "gatsby";

class SketchLink extends React.Component {
  render() {
    if (this.props.checker.node.frontmatter.belongsTo === "sketches") {
      let carouselData = this.props.checker.node.frontmatter.Carousel;
      let source = [];
      let carouselNull = true;
      let carouselSubData = carouselData.map(item => {
        if (item.carouselImage != null) source.push(`${item.carouselImage}`);
        else {
          source.push("null");
          carouselNull = true;
        }
      });

      return <SketchCollection title="Sketches" imgUrls={source} />;
    }
    return null;
  }
}

class Collection extends React.Component {
  render() {
    return (
      <div>
        <StaticQuery
          query={graphql`
            query sketchQuery {
              allMarkdownRemark {
                edges {
                  node {
                    html
                    id
                    frontmatter {
                      title
                      path
                      belongsTo
                      Description
                      heartImage
                      Carousel {
                        carouselImage
                      }
                      Cards {
                        cardImage
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <div>
              {data.allMarkdownRemark.edges.map(item => (
                <div>
                  <SketchLink checker={item} />
                </div>
              ))}
            </div>
          )}
        />
      </div>
    );
  }
}
export default Collection;
