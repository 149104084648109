import React from "react";
import "bulma/css/bulma.css";
import "../styles/global.css";
import Carousel from "../components/portartCarousel";

import Layout from "../components/layout";

import Media from "react-media";

import mainBack from "../../static/heartSec.png";

class SubCollection extends React.Component {
  render() {
    let className = "";
    if (this.props.noDesc) {
      className += " hideMe";
    }
    let imageClass = "colI";
    if (this.props.noImage) {
      imageClass += " ";
    }
    let thatIframe = "thatSubIframe";
    if (!this.props.yesIframe) {
      thatIframe += " hideFrame";
    }

    return (
      <Layout backgroundimage={mainBack} miniLogoColor={"white"}>
        <Media query="(min-width: 991px)">
          {matches =>
            matches ? (
              <div className="colW">
                <section className="hero is-fullheight myHero">
                  <div className="colC">
                    <div className="stackCol">
                      <div className="sizer">
                        <p className="stackedFirstP">{this.props.title}</p>
                      </div>

                      <div className="sizer">
                        <Carousel
                          className="colCa"
                          imgUrls={this.props.imgUrls}
                        ></Carousel>
                      </div>

                      <div className="sizer">
                        <div className="cardHolder">{this.props.children}</div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ) : (
              <section className="hero is-fullheight myHero miniHero">
                <div className="container is-fluid colC">
                  <div className="stackCol">
                    <div className="sizer">
                      <p className="stackedFirstP">{this.props.title}</p>
                    </div>

                    <div className="sizer">
                      <Carousel
                        className="colCa"
                        imgUrls={this.props.imgUrls}
                      ></Carousel>
                    </div>

                    <div className="container cardContainer">
                      <div className="cardHolder miniCardHolder">
                        {this.props.children}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
          }
        </Media>
      </Layout>
    );
  }
}
export default SubCollection;
